<template>
  <LogoutModal />
  <TimeoutModal />
  <PageView page-classes="!overflow-x-auto" body-classes="min-w-[72rem]">
    <form id="worklist-form" @submit.prevent="save">
      <!-- Worklist fields -->
      <UpsertWorkListFields
        :save="save"
        :navigate-back="navigateBack"
        @setStartDate="
          event => {
            tempStartDate = event;
          }
        "
        @setEndDate="
          event => {
            tempEndDate = event;
          }
        "
      />
      <!-- Querybuilder component -->
      <QueryBuilder
        :save="save"
        :reset="reset"
        :show-get-rules="showGetRules"
        :show-set-rules="showSetRules"
        :set-show-get-rules="setShowGetRules"
        :set-show-set-rules="setShowSetRules"
      />
      <!-- Buttons for QA -->
      <div
        v-if="isDevelopment() || isTest()"
        class="bg-white drop-shadow-md p-3 my-[1em]"
      >
        <p class="text-xl text-primary font-bold">QAology</p>
        <RuleControlButtons
          @showGetRules="toggleGetRules"
          @showSetRules="toggleSetRules"
        />
      </div>
    </form>
    <!-- Confirm to leave without saving worklist -->
    <ModalComponent
      :modal-active="showExitWorklistModal"
      :close="() => (showExitWorklistModal = false)"
      :custom-class="'w-[30em]'"
    >
      <div>
        <h1 class="text-2xl m-0.5 mb-3">
          Are you sure you want to exit without saving the worklist?
        </h1>
        <div class="mt-[2em] text-left text-base text-gray-400">
          <div class="mt-5 flex justify-between">
            <OutlinedButton
              class="w-52 mr-2 text-xs"
              type="button"
              @click="() => (showExitWorklistModal = false)"
            >
              No, go back
            </OutlinedButton>
            <SolidButton
              class="w-52 text-xs"
              type="button"
              @click="
                () => {
                  continueWithoutSaving();
                }
              "
            >
              Yes, continue without saving
            </SolidButton>
          </div>
        </div>
      </div>
    </ModalComponent>
  </PageView>
</template>

<script setup>
import PageView from '../../components/PageVue.vue';
import UpsertWorkListFields from './UpsertWorkListFields.vue';
import QueryBuilder from '../query-builder/QueryBuilder.vue';
import {
  DEFAULT_QUERY,
  DEFAULT_WORKLIST,
  useQueryBuilderStore,
} from '@/stores/useQueryBuilder';
import RuleControlButtons from '../query-builder/RuleControlButtons.vue';
import { onMounted, ref } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';
import router from '@/router';
import isEqual from 'lodash/isEqual';
import SolidButton from '@/components/buttons/SolidButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import { isDevelopment, isTest } from '@/utils/helpers';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useRoute } from 'vue-router';
import LogoutModal from './LogoutModal.vue';
import TimeoutModal from './TimeoutModal.vue';
import { useRspLookupStore } from '@/stores/useRspLookup';
import { useLookupStore } from '@/stores/useLookups';

const queryBuilder = useQueryBuilderStore();
const gameplan_store = useGamePlanStore();
const route = useRoute();
const rspLookup_store = useRspLookupStore();
const lookup_store = useLookupStore();

const showGetRules = ref(false);
const showSetRules = ref(false);
const showExitWorklistModal = ref(false);

const setShowGetRules = val => (showGetRules.value = val);
const setShowSetRules = val => (showSetRules.value = val);

const tempStartDate = ref(null);
const tempEndDate = ref(null);

const save = () => {
  const ruleValidity = queryBuilder.validateRules();

  //need to update the dates before validation
  queryBuilder.worklist.start_timest = tempStartDate.value;
  queryBuilder.worklist.end_timest = tempEndDate.value;

  const worklistValidity = queryBuilder.validateWorklist(
    route.name === 'editWorklist'
  );

  queryBuilder.setAttempt(true);
  queryBuilder.setDisabledDuringAttempt(true);

  if (ruleValidity && worklistValidity) {
    if (route.name === 'editWorklist') {
      gameplan_store.editWorklist(queryBuilder.worklist, queryBuilder.query);
    } else if (route.name) {
      gameplan_store.saveWorklist(queryBuilder.worklist, queryBuilder.query);
    }

    queryBuilder.setAttempt(false);
  } else {
    queryBuilder.setDisabledDuringAttempt(false);
  }
};

onMounted(async () => {
  await rspLookup_store.fetchRspLookup();
  await lookup_store.getPayerPlans();
});

const toggleGetRules = data => {
  showGetRules.value = data;
};

const toggleSetRules = data => {
  showSetRules.value = data;
};

const reset = () => {
  queryBuilder.resetQuery();
  gameplan_store.stepBackWorklists();
};

const continueWithoutSaving = () => {
  router.push({
    path: '/gameplan',
  });
  queryBuilder.setAttempt(false);
  reset();
  showExitWorklistModal.value = false;
};

const navigateBack = () => {
  const query = queryBuilder.getQuery;
  const worklist = queryBuilder.getWorklist;

  if (isEqual(query, DEFAULT_QUERY) && isEqual(worklist, DEFAULT_WORKLIST)) {
    continueWithoutSaving();
  } else showExitWorklistModal.value = true;
};
</script>

<style></style>

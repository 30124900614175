<template>
  <PageView page-classes="!overflow-x-auto" body-classes="min-w-[72rem]">
    <UserAnalytics />
    <div
      class="flex items-center mb-4 cursor-pointer w-fit"
      @click="toggleLeads"
    >
      <p class="text-rev-2xl text-primary font-bold">High Five</p>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="3"
        stroke="#265170"
        class="w-5 h-5 mx-2 mt-1"
        :class="showLeads ? null : '-rotate-90'"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    </div>
    <div v-if="showLeads" class="w-full text-rev-xl">
      <tabs
        ref="leadViewTabs"
        :options="{
          useUrlFragment: false,
        }"
        :nav-item-active-class="'is-active font-bold'"
        :nav-item-inactive-class="'is-inactive font-normal'"
      >
        <tab id="current-tab" name="Current">
          <div class="w-full text-left border-0 bg-white">
            <CurrentLeadTable
              :selected-row="selectedRow"
              @setSelectedRow="setSelectedRow"
              @showDetails="setSelectedAssignmentId"
            />
          </div>
        </tab>
        <tab id="recent-tab" name="Recent">
          <div class="w-full text-left border-0 bg-white drop-shadow-md">
            <RecentLeadTable
              :selected-row="selectedRow"
              :auxo_user_id="users_store.activeUser?.auxo_user_id.toString()"
              @setSelectedRow="setSelectedRow"
              @showDetails="setSelectedAssignmentId"
            />
          </div>
        </tab>
      </tabs>
    </div>

    <div
      class="flex items-center mb-4 cursor-pointer w-fit mt-4"
      @click="toggleProgress"
    >
      <p class="text-rev-2xl text-primary font-bold">WIP List</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="3"
        stroke="#265170"
        class="w-5 h-5 mx-2 mt-1"
        :class="showProgress ? null : '-rotate-90'"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    </div>
    <div v-if="showProgress" class="w-full text-sm drop-shadow-md">
      <div class="w-full text-left border-0 bg-white">
        <WipLeadTable
          :selected-row="selectedRow"
          @setSelectedRow="setSelectedRow"
          @showDetails="setSelectedAssignmentId"
        />
      </div>
    </div>
  </PageView>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useAssignmentsStore } from '@/stores/useAssignments';
import { useUsersStore } from '@/stores/useUsers';

import router from '@/router';

import CurrentLeadTable from './CurrentLeadTable.vue';
import WipLeadTable from './WipLeadTable.vue';
import RecentLeadTable from './RecentLeadTable.vue';
import PageView from '../../components/PageVue.vue';

import UserAnalytics from '@/components/UserAnalytics.vue';

/* stores */
const assignments_store = useAssignmentsStore();
const users_store = useUsersStore();
const route = useRoute();

/* local state */
const showLeads = ref(true);
const showProgress = ref(true);

/* selected row state */
const selectedRow = ref(null);

const setSelectedRow = index => {
  selectedRow.value = index;
};

onMounted(async () => {
  await assignments_store.fetchUserLead();
  await assignments_store.fetchUserWip();
  await assignments_store.fetchRecentUserAssignments();
});

const leadViewTabs = ref(null);

watch(
  route,
  async () => {
    await assignments_store.fetchUserLead();
    await assignments_store.fetchUserWip();
    await assignments_store.fetchRecentUserAssignments();
  },
  { deep: true, immediate: false }
);

const setSelectedAssignmentId = assignmentId => {
  router.push({ path: `/lead/${assignmentId}` });
};

const toggleLeads = () => {
  showLeads.value = !showLeads.value;
};

const toggleProgress = () => {
  showProgress.value = !showProgress.value;
};
</script>
<style scoped></style>

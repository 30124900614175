<template>
  <svg
    class="w-6 h-6 fill-gray-400 transition duration-75 group-hover:fill-white"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9855 3.67597C16.7722 3.46208 16.4865 3.33643 16.1847 3.32386C15.863 3.33112 15.557 3.46431 15.3325 3.69496C15.108 3.9256 14.9826 4.23498 14.984 4.55667V9.20827C14.986 9.38195 14.8595 9.5305 14.6878 9.55647C14.5942 9.5654 14.5012 9.53469 14.4317 9.4713C14.3625 9.40792 14.3231 9.31829 14.3234 9.22419V1.89445C14.3211 1.57892 14.1944 1.27681 13.9713 1.05368C13.7599 0.837843 13.4726 0.713029 13.1705 0.705488C12.8488 0.712748 12.5428 0.846221 12.3183 1.07658C12.0938 1.30722 11.9684 1.6166 11.9698 1.93858V8.34349V8.34321C11.9717 8.51689 11.8452 8.66571 11.6735 8.6914C11.5805 8.70062 11.4884 8.67018 11.4189 8.60763C11.3496 8.54509 11.31 8.45657 11.3091 8.36331V1.20227C11.3094 0.873893 11.1754 0.559487 10.9378 0.332475C10.7004 0.105742 10.3804 -0.0140469 10.0523 0.00131478C9.73009 0.00773697 9.4235 0.140647 9.19875 0.37129C8.97398 0.602212 8.84916 0.91215 8.85139 1.23408V8.35545C8.85335 8.52912 8.72686 8.67795 8.55513 8.70364C8.46215 8.71285 8.37001 8.68242 8.30076 8.61987C8.23151 8.55732 8.19159 8.46881 8.19074 8.37555V2.67516C8.19102 2.36159 8.06817 2.0603 7.84898 1.83582C7.62978 1.6116 7.33157 1.48177 7.018 1.4745C6.69577 1.48065 6.38918 1.61356 6.16444 1.84448C5.93966 2.07512 5.81485 2.38506 5.81708 2.70726V11.6618L4.61611 10.0406C4.38268 9.73287 4.03672 9.53043 3.65418 9.4771C3.27165 9.42377 2.88354 9.52429 2.57466 9.75632C2.2812 9.98026 2.08323 10.307 2.02096 10.6708C1.95869 11.0349 2.03632 11.4088 2.23848 11.7176L4.42396 15.2125C4.61774 15.5179 4.78779 15.8376 4.93243 16.1691L4.97236 16.2612C7.30224 21.5372 14.9198 21.1368 16.6811 15.6447C17.1139 14.298 17.3339 12.8921 17.3336 11.4775V4.51643C17.3322 4.20146 17.2071 3.89962 16.9854 3.67566L16.9855 3.67597Z"
    />
  </svg>
</template>

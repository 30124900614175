<template>
  <div
    class="bg-white p-2 justify-center items-center text-rev-sm text-primary relative"
  >
    <table
      v-if="documents_store.documents.length > 0"
      class="table-auto w-full text-left border-0 drop-shadow-none"
    >
      <thead class="text-rev-sm sticky text-primary font-bold">
        <tr>
          <template v-for="(header, index) in DOCUMENTS_HEADERS" :key="index">
            <TableHeader
              :id="header.key"
              :type="header.type"
              :show-sort="true"
              :title="header.value"
              title-styling="text-primary font-bold"
              @sort="sort"
            />
          </template>
        </tr>
      </thead>

      <tbody
        v-show="!documents_store.isLoading"
        class="text-rev-sm text-gray-700"
      >
        <tr
          v-for="(doc, itemIndex) in documents_store.documentsPaginated"
          :key="itemIndex"
          :data-index="itemIndex"
          :class="itemIndex % 2 === 1 ? 'bg-gray-100' : 'bg-white'"
          class="cursor-pointer"
          @click="loadDocument(doc.doc_id, doc.doc_version)"
        >
          <td
            v-for="(header, headerIndex) in DOCUMENTS_HEADERS"
            :key="headerIndex"
          >
            <TableCellVue :item="doc" :header="header" />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-show="
        documents_store.documents?.length == 0 && !documents_store.isLoading
      "
      class="flex bg-white h-36 p-2 justify-center items-center text-primary text-rev-base"
    >
      No documents found
    </div>
    <div
      v-show="documents_store.isLoading"
      class="flex items-center justify-center"
    >
      <LoadingSpinner />
    </div>
    <VuePagination
      v-if="!documents_store.isLoading"
      :page="documents_store.documentsPage"
      local_store="documents_pagination"
      type="Recent Documents"
      :records="documents_store.documentsRecords"
      :limit="Number(documents_store.documentsLimit)"
      :update_on="documents_store.documentsPage"
      :show_page_buttons="false"
      @paginate="paginate"
      @updateLimit="updateLimit"
    />
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { useDocumentsStore } from '@/stores/useDocuments';
import TableHeader from '@/components/table/TableHeader.vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import VuePagination from '@/views/VuePagination.vue';
import TableCellVue from '../../common/TableCellView.vue';

const route = useRoute();

const documents_store = useDocumentsStore();

const DOCUMENTS_HEADERS = [
  { value: 'Document Name', key: 'descr', sort: true },
  { value: 'Document Type', key: 'doc_type', sort: true },
  {
    value: 'Last Updated',
    key: 'mod_dtm',
    type: 'date',
    format: 'Pp',
    truncateTimezone: false,
    sort: true,
  },
  { value: 'User', key: 'mod_by', sort: true },
];

function sort(order = 'asc', field) {
  documents_store.documentsPage = 1;
  documents_store.documents.value = documents_store.sortDocs(order, field);
}

const loadDocument = async (doc_id, doc_version) => {
  await documents_store.fetchDocumentByWorkNumberAndVersion(
    doc_id,
    doc_version
  );
  router.push({
    path: `/document/${route.params.id}`,
  });
};

async function paginate(page) {
  documents_store.documentsPage = page;
}

async function updateLimit(limit) {
  documents_store.documentsPage = 1;
  documents_store.documentsLimit = Number(limit.value);
}

onMounted(() => {
  const current_limit = useLocalStorage(
    'documents_pagination',
    Number(documents_store.documentsLimit)
  );
  documents_store.documentsLimit = current_limit;
});
</script>
<style scoped>
tr td {
  width: 25%;
}
</style>

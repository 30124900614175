<template>
  <!-- disabled dropdown -->
  <button
    v-show="props.disabled"
    class="text-white bg-gray-300 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
    type="button"
    disabled
  >
    {{ actionSelected[props.textKey] || props.buttonText }}
  </button>

  <!-- Dropdown button -->
  <button
    v-show="!props.disabled"
    :id="'dropDownButton-' + props.uniqueIdString"
    :data-dropdown-toggle="'dropdown-' + props.uniqueIdString"
    data-dropdown-offset-distance="10"
    data-dropdown-placement="bottom"
    :class="`relative inline-flex items-center text-sm focus:ring-2 font-medium rounded-lg px-5 py-2.5 text-center ${
      props.variant == 'primary'
        ? 'text-primary hover:text-white border border-primary hover:bg-primary mr-2 mb-2'
        : 'justify-between text-primary-600 border border-gray-300 bg-gray-50 '
    }`"
    type="button"
  >
    <!-- Render Selection Text if they have picked something, else default label provided by prop -->
    <span v-if="actionSelected !== ''">
      {{ actionSelected[props.textKey] }}
    </span>
    <span v-else>
      {{ props.buttonText }}
    </span>

    <!-- Dropdown icon -->
    <svg
      class="w-2.5 h-2.5 ms-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 4 4 4-4"
      />
    </svg>
  </button>

  <!-- Dropdown Menu -->
  <div
    :id="'dropdown-' + props.uniqueIdString"
    class="left-0 z-10 hidden absolute bg-white divide-y divide-gray-100 rounded-lg shadow"
    :class="{
      'max-w-20rem w-auto': props.showSearch === true,
      'w-fit': props.showSearch === false,
    }"
  >
    <!-- Optional Search Bar -->
    <div v-show="props.showSearch === true" class="p-3">
      <div class="relative">
        <div
          class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none"
        >
          <img :src="SearchIcon" class="w-4 h-4 text-gray-500" />
        </div>
        <input
          :id="'input-' + props.buttonText + '-search'"
          v-model="searchVal"
          type="text"
          class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          :placeholder="'Search ' + props.buttonText"
        />
      </div>
    </div>
    <!-- Options List -->
    <ul
      v-if="props.showSearch === false"
      :id="'dropwdownList-' + props.uniqueIdString"
      class="options-list"
    >
      <li
        v-for="item in props.options"
        :key="item[props.textKey]"
        class="options-li"
      >
        <a
          :id="'option-' + item[props.textKey] + props.uniqueIdString"
          class="option"
          :class="{
            'selected-option':
              item[props.textKey] === actionSelected[props.textKey],
          }"
          @click="selectActionByClick(item)"
        >
          {{ item[props.textKey] }}
        </a>
      </li>
    </ul>
    <!-- Searchable options list -->
    <ul
      v-else
      :id="'dropwdownList-' + props.uniqueIdString"
      class="searchable-options-list"
    >
      <li
        v-for="item in findMatches(searchVal)"
        :key="item[props.textKey]"
        class="options-li"
      >
        <a
          :id="'option-' + item[props.textKey] + props.uniqueIdString"
          class="option"
          :class="{
            'selected-option':
              item[props.textKey] === actionSelected[props.textKey],
          }"
          @click="selectActionByClick(item)"
        >
          {{ item[props.textKey] }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { initDropdowns } from 'flowbite';
import { ref, defineProps, defineEmits, onMounted, watch } from 'vue';
import SearchIcon from '@/assets/search-icon-fb.svg';

const props = defineProps({
  /** REQUIRED: option list, should be list of dict [{'id': 1, 'text': 'Display this!'}] */
  options: {
    default: () => [],
    type: Array,
  },
  /** REQUIRED if more than 1 on same page: used to id the dropdown, should be unique across page */
  uniqueIdString: {
    default: '',
    type: String,
  },
  /** REQUIRED: key attr that holds text to display in list as option */
  textKey: {
    default: '',
    type: String,
  },
  /** Optional: Dropdown buttons text to display */
  buttonText: {
    default: 'Select',
    type: String,
  },
  /** Optional: renders search option, also makes component scrollable */
  showSearch: {
    default: false,
    type: Boolean,
  },
  /** Optional: If the parent ever updates its record of the selection, we should pass it in
   *  so we can rerender the correct dropdown label if it is cleared
   */
  parentSelection: {
    default: () => {},
    type: Object,
  },
  /** Optional: Disables the element */
  disabled: {
    default: false,
    type: Boolean,
  },
  /** Optional: More button styling */
  buttonClass: {
    default: '',
    type: String,
  },
  variant: {
    default: 'primary',
    type: String,
  },
});
const dropdownButton = ref('');
const dropdownMenu = ref('');
const dropdownList = ref('');
const dropdownItems = ref('');
let currentIndex = -1;

const actionSelected = ref('');
const searchVal = ref('');

onMounted(() => {
  initDropdowns();
  dropdownButton.value = document.getElementById(
    'dropDownButton-' + props.uniqueIdString
  );
  dropdownMenu.value = document.getElementById(
    'dropdown-' + props.uniqueIdString
  );
  dropdownList.value = document.getElementById(
    'dropwdownList-' + props.uniqueIdString
  );
  dropdownItems.value = dropdownList.value.getElementsByTagName('a');

  document.addEventListener('keydown', function (e) {
    // make sure dropdown is showing
    if (!dropdownMenu.value.classList.contains('hidden')) {
      let renderedOptions = findMatches(searchVal.value);
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          currentIndex = (currentIndex + 1) % dropdownItems.value.length;
          selectActionFromKeyboard(renderedOptions[currentIndex]);
          break;
        case 'ArrowUp':
          e.preventDefault();
          currentIndex =
            (currentIndex - 1 + dropdownItems.value.length) %
            dropdownItems.value.length;
          selectActionFromKeyboard(renderedOptions[currentIndex]);
          break;
        case 'Enter':
          e.preventDefault();
          dropdownMenu.value.classList.toggle('hidden');
      }
    }
  });
});

const selectActionByClick = item => {
  actionSelected.value = item;
  emit('updateSelection', item);
  dropdownButton.value.click();
};

const selectActionFromKeyboard = item => {
  actionSelected.value = item;
  emit('updateSelection', item);
  let optionToScrollTo = document.getElementById(
    'option-' + item[props.textKey] + props.uniqueIdString
  );
  optionToScrollTo.scrollIntoView({
    behavior: 'instant',
    block: 'nearest',
    inline: 'nearest',
  });
};

/** Implements search functionallity for dropdown */
function findMatches(v) {
  if (!v || v === '') return props.options;
  const matches = props.options.filter(option =>
    option?.value
      ?.toLowerCase()
      .trim()
      .includes(v?.toLowerCase().trim() || null)
  );
  return matches;
}

// resets dropdown label if parent changes its record
// of the value to null
watch(
  () => props.parentSelection,
  newVal => {
    if (!newVal) {
      actionSelected.value = '';
      return;
    }
    actionSelected.value = newVal;
    currentIndex = props.options.findIndex(val => val === newVal);
  },
  { immediate: true }
);

const emit = defineEmits(['updateSelection']);
</script>

<!-- scoping these styles overrides css coming from tabs component in ActivityStream.vue & ActivityTab.vue -->
<style scoped>
.options-list,
.searchable-options-list {
  @apply flex-col bg-white py-2 text-sm text-gray-700 cursor-pointer;
}

.searchable-options-list {
  @apply overflow-y-auto max-h-60 whitespace-nowrap;
}

.options-li {
  @apply shadow-none text-black;
}

.option {
  @apply block px-4 py-2 hover:bg-primary-100 bg-white py-[.5rem] px-[1rem];
}

.selected-option {
  @apply border-2 border-primary-200 rounded-lg;
}
</style>

<template>
  <div class="flex-1">
    <label v-if="label" class="font-bold p-0.5 py-1 text-gray-500">{{
      props.label
    }}</label>
    <div :ref="datePickerRef" class="relative" :class="props.class">
      <div
        class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
      >
        <svg
          class="w-4 h-4 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
          />
        </svg>
      </div>
      <input
        ref="dateInput"
        :id="props.uniqueIdString"
        :disabled="props.disabled"
        type="text"
        :placeholder="placeholder"
        :class="`${
          props.error ? ' border-2 border-red-500' : 'border-gray-300'
        } ${disabled ? 'cursor-default' : 'cursor-pointer'}`"
        class="bg-gray-50 border text-gray-900 text-sm rounded block w-full ps-10 px-2.5 py-[7px]"
        @changeDate="handleChangeDate"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { Datepicker } from 'flowbite';
import { parseISO } from 'date-fns';

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Select date',
  },
  minDate: {
    type: String,
    default: '',
  },
  maxDate: {
    type: String,
    default: '',
  },
  modelValue: {
    default: null,
    type: String,
  },
  //This should be unique across all datepickers
  uniqueIdString: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  error: {
    default: false,
    type: Boolean,
  },
  class: {
    default: '',
    type: String,
  },
});

const datePickerRef = ref(null);
const dateInput = ref(null);
let datePickerInstance = null;

const initializeDatePicker = () => {
  const options = {
    autohide: true,
    format: 'mm/dd/yyyy',
    orientation: 'bottom',
    buttons: true,
    minDate: props.disabled ? '' : props.minDate,
    maxDate: props.maxDate,
  };

  datePickerInstance = new Datepicker(dateInput.value, options);

  if (props.modelValue) {
    //if the date is in the form yyyy/mm/dd, new Date() makes it yesterday for some reason so we have to do this
    if (props.modelValue.split('-')[0].length == 4) {
      const parsedDate = parseISO(props.modelValue);
      datePickerInstance.setDate(parsedDate);
    } else {
      datePickerInstance.setDate(new Date(props.modelValue));
    }
  } else {
    dateInput.value.value = '';
  }

  //the buttons are a package deal in flowbite so we have to be sneaky and hide the today one :)
  const todayButton = document.querySelectorAll('.today-btn');
  todayButton.forEach(element => {
    element.style.display = 'none';
  });

  const clearButton = document.querySelectorAll('.clear-btn');
  clearButton.forEach(element => {
    element.style.width = '100%';
    element.style.margin = '0px';
  });
};

// Watch for changes in the date props and update the datepicker instance
watch(
  () => [props.minDate, props.maxDate, props.modelValue],
  () => {
    initializeDatePicker();
  }
);

onMounted(() => {
  initializeDatePicker();
});

const handleChangeDate = event => {
  if (event.target.value) {
    emit('update', event.target.value);
  } else {
    //we need it to be null in the backend
    emit('update', null);
  }
};

const emit = defineEmits(['update']);
</script>

<template>
  <nav>
    <ul class="inline-flex items-center -space-x-px text-base h-10">
      <!-- Previous Button -->
      <li>
        <button>
          <a
            id="previous-page-fb-pagination"
            class="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 border border-e-0 border-gray-300 rounded-s-lg"
            tabindex="0"
            :class="{
              'cursor-not-allowed': props.page === 1,
              'hover:bg-gray-100 hover:text-gray-700': props.page !== 1,
            }"
            @click="updatePage(props.page - 1)"
            >Previous</a
          >
        </button>
      </li>

      <!-- Page Numbers -->
      <template
        v-for="(item, index) in Math.ceil(records / props.limit)"
        :key="index"
      >
        <li v-if="!showPaginationSolidButton(index + 1)">
          <button>
            <a
              :id="'searchmodal-pagination-button-' + index"
              class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              :class="
                index + 1 === props.page ? 'bg-gray-300 text-primary' : ''
              "
              :disabled="index + 1 === props.page"
              tabindex="0"
              @click="updatePage(index + 1)"
            >
              {{ index + 1 }}</a
            >
          </button>
        </li>
      </template>

      <!-- Next Button -->
      <li class="mr-20">
        <button>
          <a
            id="next-page-fb-pagination"
            class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg"
            tabindex="0"
            :class="{
              'cursor-not-allowed': props.page === getLast(),
              'hover:bg-gray-100 hover:text-gray-700': props.page !== getLast(),
            }"
            @click="updatePage(props.page + 1)"
            >Next</a
          >
        </button>
      </li>
    </ul>
  </nav>

  <!-- Current range and total item count -->
  <div class="flex w-fit h-fit items-center text-gray-500 text-xs">
    <div>
      {{ (page - 1) * props.limit + 1 }} - {{ current_end_range }} {{ type }} of
      {{ records }} Total Items
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import { computed } from 'vue';
const props = defineProps({
  type: {
    default: 'Records',
    type: String,
  },
  page: {
    default: 1,
    type: Number,
  },
  limit: {
    default: 10,
    type: Number,
  },
  records: {
    default: 0,
    type: Number,
  },
  /** Enables left and right arrows change pages */
  useArrowKeylisteners: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(['paginate']);

const current_end_range = computed(() => {
  return props.records < props.page * props.limit
    ? props.records
    : props.page * props.limit;
});

onMounted(() => {
  if (props.useArrowKeylisteners) {
    window.addEventListener('keydown', handleArrowKeyPress);
  }
});

onUnmounted(() => {
  if (props.useArrowKeylisteners) {
    window.removeEventListener('keydown', handleArrowKeyPress);
  }
});

/** listner for arrow keys to change pages accordingly */
const handleArrowKeyPress = event => {
  // bools for boundchecking
  // will be true if we are in-bounds
  let leftBoundry = props.page - 1 > 0;
  let rightBoundry = props.page < getLast();

  if (event.key === 'ArrowLeft' && leftBoundry) {
    updatePage(props.page - 1);
  } else if (event.key === 'ArrowRight' && rightBoundry) {
    updatePage(props.page + 1);
  }

  // user tabbed to previous/pagenums/next buttons
  // ' ' = space bar
  if (event.key === ' ') {
    let activeElement = document.activeElement;
    if (activeElement.id === 'previous-page-fb-pagination' && leftBoundry) {
      updatePage(props.page - 1);
    }

    if (activeElement.id === 'next-page-fb-pagination' && rightBoundry) {
      updatePage(props.page + 1);
    }

    if (activeElement.id.includes('searchmodal-pagination-button-')) {
      // parse page number
      let splitId = activeElement.id.split('-');
      let pageNum = Number(splitId[splitId.length - 1]) + 1;
      if (pageNum === props.page) {
        return;
      }
      updatePage(pageNum);
    }
  }
};

/** determines what page numbers should be rendered */
function showPaginationSolidButton(index) {
  // Meet acceptance criteria, on page 1, 2 and 3 should be showing
  // on last page, two previous pages should be showing
  if (props.page == 1) {
    return index > 3;
  }
  if (props.page == getLast()) {
    return index < getLast() - 2;
  }
  // show one on either side of current page
  return Math.abs(index - props.page) > 1;
}

/** checks boundry and tells parent to change pages */
function updatePage(page) {
  if (page > 0 && page < getLast() + 1) {
    emit('paginate', page);
  }
}

function getLast() {
  return Math.ceil(props.records / props.limit);
}
</script>

<style scoped></style>

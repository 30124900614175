<template>
  <div
    v-if="path !== '/login' && path !== '/status'"
    class="title w-full h-[4.5em] flex items-center justify-between bg-primary text-gray-100 absolute z-50"
    @mouseleave="updateStatsDetails(false)"
  >
    <div class="flex w-full justify-between items-center text-2xl px-5 py-1">
      <img src="@/assets/auxo-White-3C.png" class="max-h-14" />
      <div
        v-show="metrics_store?.activeUserDailyGoal > 0"
        class="flex items-center relative"
        @mouseover="updateStatsDetails(true)"
      >
        <div class="mr-2">
          <h3 class="text-lg text-center font-semibold">Today's Goal</h3>
          <p class="text-sm text-center">
            <span class="font-semibold">{{
              metrics_store.activeUserDailyGoal
            }}</span>
            work items
          </p>
        </div>
        <div class="relative">
          <CircleProgress
            :percent="completedRatio >= 100 ? 100 : completedRatio"
            fill-color="#fabb28"
            :size="50"
            :border-bg-width="5"
            :border-width="5"
          />
          <div
            class="absolute top-[10px] text-[12px] w-[50px] h-[50px] text-center flex items-center justify-center"
          >
            <div class="w-full h-full">{{ completedRatio }}%</div>
          </div>
        </div>
        <!-- User Streaks Icon and Count -->
        <div
          v-show="
            metrics_store.activeUserDailyGoalStreak != null &&
            metrics_store.activeUserDailyGoalStreak != 0
          "
          class="mx-4 flex items-end"
        >
          <img :src="StreakIcon" />
          <span class="text-sm">{{
            `x${metrics_store.activeUserDailyGoalStreak}`
          }}</span>
        </div>
        <div v-show="metrics_store.showMetrics" class="stats-dropdown">
          <StatsDropdown :update-stats-details="updateStatsDetails" />
        </div>
      </div>
      <div class="relative flex justify-between items-center select-none">
        <SearchBarVue :show-metrics="metrics_store.setShowMetrics" />
        <div
          ref="dropdownRef"
          class="cursor-pointer"
          @click="showUserOptions = !showUserOptions"
        >
          <UserPicture :user="users_store.activeUser" :disable-popper="true" />
        </div>
      </div>
      <Transition>
        <div
          v-if="showUserOptions"
          class="absolute flex flex-col rounded-b font-semibold right-0 top-[72px] border-b border-l border-r border-primary bg-white text-primary text-sm w-[150px] mr-2 z-20"
        >
          <div
            class="text-center py-2 w-full hover:bg-primary-100 rounded cursor-pointer"
            @click="signOut"
          >
            Sign Out
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';
import SearchBarVue from './SearchBar.vue';
import StatsDropdown from './StatsDropdown.vue';
import { onClickOutside } from '@vueuse/core';
import { useUsersStore } from '@/stores/useUsers';
import { useMetrics } from '@/stores/useMetrics';
import 'vue3-circle-progress/dist/circle-progress.css';
import CircleProgress from 'vue3-circle-progress';
import UserPicture from './UserPicture.vue';
import StreakIcon from '@/assets/flame-gradient.svg';
import { useGamePlanStore } from '@/stores/useGamePlans';

async function signOut() {
  if (gameplan_store.editMode) {
    gameplan_store.logoutModal = true;
    return;
  }

  users_store.logOut().then(() => {
    showUserOptions.value = false;
  });
}

const route = useRoute();
const path = computed(() => route.path);
const users_store = useUsersStore();
const metrics_store = useMetrics();
const gameplan_store = useGamePlanStore();

const showUserOptions = ref(false);
const dropdownRef = ref(null);

const usersDailyGoal = ref(null);

const updateStatsDetails = val => {
  metrics_store.setShowMetrics(val);
};

onMounted(async () => {
  usersDailyGoal.value = metrics_store?.activeUserDailyGoal;
});

const completedRatio = computed(() => {
  const { activeUserDailyGoal: goal, activeUserDailyComplete: complete } =
    metrics_store;

  if (!goal) return 0;

  return goal === 0 && complete === 0
    ? 100
    : Math.floor((complete * 100) / goal);
});

onClickOutside(
  dropdownRef,
  () => {
    showUserOptions.value = false;
  },
  {
    ignore: [],
  }
);
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: transform 0.25s ease-in-out;
  transform-origin: top;
}

.v-enter-from,
.v-leave-to {
  transform: scaleY(0);
}

.stats-dropdown {
  @apply bg-[#305977] top-[61px] w-[20.5em] rounded-b-sm pb-2 pr-2;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  animation: 1s linear 0s slide;
  z-index: 35;
}
</style>

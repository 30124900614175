<template>
  <div class="w-full">
    <div class="p-3 bg-gray-100 w-full cursor-default h-full overflow-scroll">
      <div class="controlBar">
        <div class="flex items-center mt-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="3"
            stroke="#265170"
            class="w-6 h-6 cursor-pointer"
            @click="goBackToLeads()"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
          <div>
            <p class="text-2xl text-primary font-bold ml-3">Appeal Letter</p>
            <p class="text-sm text-slate-600 ml-3">
              Customize your document below
            </p>
          </div>
        </div>

        <div class="flex items-center mr-7">
          <SolidButton class="mr-[1em] w-16" @click="saveDocument()">
            Save
          </SolidButton>
          <OutlinedButton class="w-16" @click="printDocument()">
            Print
          </OutlinedButton>
        </div>
      </div>
      <div
        v-show="showLoader"
        class="centered h-[70vh] flex justify-center items-center"
      >
        <LoadingSpinner />
      </div>
      <div v-show="!showLoader" class="centered h-[70vh]">
        <editor
          id="tiny"
          api-key="no-api-key"
          :initial-value="tinyMCE"
          :init="{
            license_key: 'gpl', // open src license, gets rid of console warning
            height: 680,
            width: '80%',
            menubar: true,
            promotion: false, // removes 'Upgrade' promotion button
            plugins: 'lists help wordcount',
            validElements: 'p,img,a[href|target=_blank],strong/b,div[align],br',
            toolbar:
              'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
          }"
          @init="$event => setLoaded()"
        />
      </div>
      <SupportPopper
        v-if="!rbac_store.isFeatureFlagEnabled('SIDEBAR_FLOWBITE')"
      />
    </div>
    <!-- inner modal -->
    <ModalComponent
      :modal-active="showPrintPreview"
      :close="async () => await togglePrintPreview(false)"
      :custom-style="{
        width: '60%',
        height: '95%',
        overflow: 'overlay',
      }"
    >
      <div>
        <p class="text-3xl text-primary text-center font-bold mb-3">
          Document Print Preview
        </p>
        <!-- eslint-disable-next-line -->
        <div id="printContent" v-html="currentInnerHTML"></div>
        <div class="flex justify-center items-center mt-2">
          <SolidButton class="w-16 mr-3" @click="window.print()">
            Print
          </SolidButton>
          <OutlinedButton
            class="w-16"
            @click="async () => await togglePrintPreview(false)"
          >
            Cancel
          </OutlinedButton>
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
// all imports needed for tinymce to be self hosted, beware of removing any of these
// ensure that they come beofre 'editor' for correct functionallity
import 'tinymce/tinymce';
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom/model';
import 'tinymce/skins/ui/oxide/skin';
import 'tinymce/skins/ui/oxide/content';
import 'tinymce/skins/content/default/content';

import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/help/plugin';
import 'tinymce/plugins/help/js/i18n/keynav/en';
import 'tinymce/plugins/wordcount/plugin';

// vue tincymce actual editor
import editor from '@tinymce/tinymce-vue';

import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';

import { useDocumentsStore } from '@/stores/useDocuments';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

import router from '@/router';
import ModalComponent from '../../components/ModalComponent.vue';
import SupportPopper from '@/components/SupportPopper.vue';
import { toast } from 'vue3-toastify';

import sanitizeHtml from 'sanitize-html';
import SolidButton from '@/components/buttons/SolidButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';

import { useRbacStore } from '@/stores/useRbac';

const rbac_store = useRbacStore();
const showLoader = ref(true);

const setLoaded = () => {
  showLoader.value = false;
};

// have innerHTML available to be updated
let currentInnerHTML = ref(undefined);

// Setup store
const documents_store = useDocumentsStore();

// Setup route and have workItemId available (allow user to go back)
const route = useRoute();

// Setup print preview modal
let showPrintPreview = ref(false);

const togglePrintPreview = async value => {
  showPrintPreview.value = value;
};

// Get current instance by ID (fun gotcha while setting this up) and run
// tiny MCE's built in method to get innerHTML content of it's textarea
const printDocument = async () => {
  showPrintPreview.value = true;
  const currentTinyInstance = getTinymce();
  currentInnerHTML.value = currentTinyInstance.get('tiny').getContent();
};

// If the data isn't loaded, bounce them back to the lead/workitem page
onMounted(async () => {
  if (!documents_store.currentDocument) {
    router.push({ path: `/lead` });
    toast.error('Document does not exist!', {
      autoClose: 5000,
    });
  }
});

// Update the currently loaded document and push back to the work item
const saveDocument = async () => {
  const currentTinyInstance = getTinymce();
  currentInnerHTML.value = sanitizeHtml(
    currentTinyInstance.get('tiny').getContent(),
    {
      allowedTags: ['img', 'p', 'div'],
      allowedAttributes: {
        div: ['style'],
        img: ['src', 'style'],
        p: ['style'],
      },
      allowedSchemes: ['https'],
    }
  );
  await documents_store.updateDocument(currentInnerHTML.value);
  router.push({ path: `/lead/${route.params.claim}` });
};

// Return to related main work Item
const goBackToLeads = async () => {
  router.push({ path: `/lead/${route.params.claim}` });
};

let tinyMCE = documents_store.currentDocument;
</script>

<style scoped>
.centered {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.controlBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.button {
  max-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#printContent {
  border: 1px solid lightslategray;
  padding: 2% 7% 4%;
}

@media print {
  * {
    visibility: hidden !important;
    height: 0px;
    position: static !important;
  }

  #printContent {
    position: absolute !important;
    visibility: visible !important;
    padding: 5mm 25mm;
    height: 100%;
    z-index: 25;
    background-color: white;
    width: 100vw !important;
    top: 0;
    left: 0;
  }

  @page {
    size: auto;
    margin: 0mm;
  }
}
</style>

<template>
  <li
    v-show="
      metrics_store.activeUserDailyGoal != null &&
      metrics_store.activeUserDailyGoal != 0
    "
  >
    <div
      class="flex flex-col items-center justify-center h-[4.5rem] px-2 py-2 my-4 text-base bg-amber-300 rounded-xl font-normal rounded-lg dark:text-white group transition-all duration-300"
      :class="{ 'w-[3.5rem]': collapsed, 'w-full': !collapsed }"
    >
      <div
        v-show="!collapsed && !collapsedAfterAnimations"
        class="flex flex-row justify-between items-center w-full gap-2"
      >
        <div class="flex flex-row gap-1">
          <div
            v-show="
              metrics_store.activeUserDailyGoalStreak != null &&
              metrics_store.activeUserDailyGoalStreak != 0
            "
            class="max-h-9 mr-2"
          >
            <img :src="fireIcon" class="max-h-9" />
            <span
              class="relative rounded-full bg-white text-black p-1 bottom-6 left-[.75rem] text-[.55rem] font-light border-[1px] border-black"
            >
              x{{ metrics_store.activeUserDailyGoalStreak }}
            </span>
          </div>
          <div class="flex flex-col gap-2 pl-1">
            <span class="text-xs text-black h-3.5 w-12 whitespace-nowrap">
              TODAY'S GOAL:
            </span>
            <span
              class="font-bold text-sm text-black h-3.5 w-12 whitespace-nowrap"
            >
              {{ metrics_store.activeUserDailyGoal }} Work Items
            </span>
          </div>
        </div>
        <FlowbiteStatsInfoIcon class="cursor-pointer" @click="openStats" />
      </div>
      <div
        v-show="!collapsed && !collapsedAfterAnimations"
        class="flex flex-row justify-center items-baseline gap-2 w-full"
      >
        <div class="w-full h-1.5 mb-1.5 bg-blue-900 rounded-full">
          <div
            class="h-1.5 bg-sky-400 rounded-full"
            :style="{
              width:
                (completedRatio >= 100 ? 100 : completedRatio).toString() + '%',
            }"
          />
        </div>
        <div class="text-black text-xs">{{ completedRatio }}%</div>
      </div>
      <div v-show="collapsed || collapsedAfterAnimations" class="w-full h-10">
        <span
          v-show="!streakShown"
          class="transition-all duration-500"
          :class="{
            'opacity-0': progressBarOpacity,
            'opacity-100': !progressBarOpacity,
          }"
        >
          <CircleProgress
            :percent="completedRatio >= 100 ? 100 : completedRatio"
            fill-color="#14B1E7"
            empty-color="#0F2E44"
            :size="40"
            :border-bg-width="5"
            :border-width="5"
          />
        </span>
        <div
          class="relative text-[10px] text-black w-[50px] h-[10px] text-center flex items-center justify-center"
          :class="{
            'bottom-[31px]': !streakShown,
            'right-[5px]': !streakShown,
            'right-[6px]': streakShown,
          }"
        >
          <div
            v-show="!streakShown"
            class="w-full h-full transition-all duration-500"
            :class="{
              'opacity-0': progressBarOpacity,
              'opacity-100': !progressBarOpacity,
            }"
          >
            {{ completedRatio }}%
          </div>
          <div
            v-show="streakShown"
            class="w-full h-full flex justify-center items-center transition-all duration-500"
            :class="{
              'opacity-0': streakOpacity,
              'opacity-100': !streakOpacity,
            }"
          >
            <div>
              <span
                class="relative rounded-full bg-white text-black p-1 top-9 left-2 text-[.55rem] font-light border-[1px] border-black z-10"
              >
                x{{ metrics_store.activeUserDailyGoalStreak }}
              </span>
              <img :src="fireIcon" class="relative max-h-9" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="stats"
      ref="target"
      class="flex flex-col absolute left-[18rem] top-[4rem] w-[28rem] h-40 drop-shadow bg-white rounded-lg text-black justify-evenly px-4"
    >
      <div class="flex flex-row gap-0 px-6 justify-center">
        <div
          class="rounded-l-lg px-4 py-1 text-center text-sm w-40 cursor-pointer"
          :class="{
            'bg-amber-300': statsTime == statsTimeStates.TODAY,
            'bg-gray-100': statsTime != statsTimeStates.TODAY,
            'text-gray-400': statsTime !== statsTimeStates.TODAY,
            'font-light': statsTime != statsTimeStates.TODAY,
            'font-bold': statsTime == statsTimeStates.TODAY,
          }"
          @click="changeStatsTime(statsTimeStates.TODAY)"
        >
          Today
        </div>
        <div
          class="px-4 py-1 text-center text-sm w-40 cursor-pointer"
          :class="{
            'bg-amber-300': statsTime === statsTimeStates.WEEK,
            'bg-gray-100': statsTime !== statsTimeStates.WEEK,
            'text-gray-400': statsTime !== statsTimeStates.WEEK,
            'font-light': statsTime != statsTimeStates.WEEK,
            'font-bold': statsTime == statsTimeStates.WEEK,
          }"
          @click="changeStatsTime(statsTimeStates.WEEK)"
        >
          Last Week
        </div>
        <div
          class="rounded-r-lg px-4 py-1 text-center text-sm w-40 cursor-pointer"
          :class="{
            'bg-amber-300': statsTime === statsTimeStates.MONTH,
            'bg-gray-100': statsTime !== statsTimeStates.MONTH,
            'text-gray-400': statsTime !== statsTimeStates.MONTH,
            'font-light': statsTime != statsTimeStates.MONTH,
            'font-bold': statsTime == statsTimeStates.MONTH,
          }"
          @click="changeStatsTime(statsTimeStates.MONTH)"
        >
          Last Month
        </div>
      </div>
      <div class="flex flex-row gap-6 justify-center">
        <div class="flex flex-col gap-2">
          <div v-show="statsTime === statsTimeStates.TODAY" class="font-bold">
            {{ metrics_store.activeUserDailyAssigned }}
          </div>
          <div v-show="statsTime === statsTimeStates.WEEK" class="font-bold">
            {{ metrics_store.activeUserWeeklyAssigned }}
          </div>
          <div v-show="statsTime === statsTimeStates.MONTH" class="font-bold">
            {{ metrics_store.activeUserMonthlyAssigned }}
          </div>
          <div class="font-light text-sm text-gray-400">Assigned</div>
        </div>
        <div class="flex flex-col gap-2">
          <div v-show="statsTime === statsTimeStates.TODAY" class="font-bold">
            {{ metrics_store.activeUserDailyWip }}
          </div>
          <div v-show="statsTime === statsTimeStates.WEEK" class="font-bold">
            {{ metrics_store.activeUserWeeklyWip }}
          </div>
          <div v-show="statsTime === statsTimeStates.MONTH" class="font-bold">
            {{ metrics_store.activeUserMonthlyWip }}
          </div>
          <div class="font-light text-sm text-gray-400">Added to WIP</div>
        </div>
        <div class="flex flex-col gap-2">
          <div v-show="statsTime === statsTimeStates.TODAY" class="font-bold">
            {{ metrics_store.activeUserDailyComplete }}
          </div>
          <div v-show="statsTime === statsTimeStates.WEEK" class="font-bold">
            {{ metrics_store.activeUserWeeklyComplete }}
          </div>
          <div v-show="statsTime === statsTimeStates.MONTH" class="font-bold">
            {{ metrics_store.activeUserMonthlyComplete }}
          </div>
          <div class="font-light text-sm text-gray-400">Complete</div>
        </div>
        <div class="flex flex-col gap-2">
          <div v-show="statsTime === statsTimeStates.TODAY" class="font-bold">
            {{
              formatMilliseconds(metrics_store.activeUserDailyTimeToComplete)
            }}
          </div>
          <div v-show="statsTime === statsTimeStates.WEEK" class="font-bold">
            {{
              formatMilliseconds(metrics_store.activeUserWeeklyTimeToComplete)
            }}
          </div>
          <div v-show="statsTime === statsTimeStates.MONTH" class="font-bold">
            {{
              formatMilliseconds(metrics_store.activeUserMonthlyTimeToComplete)
            }}
          </div>
          <div class="font-light text-sm text-gray-400">Time/Work Item</div>
        </div>
      </div>
      <div
        class="absolute top-2 right-4 text-sm cursor-pointer"
        @click="closeStats"
      >
        X
      </div>
    </div>
  </li>
</template>
<script setup>
import CircleProgress from 'vue3-circle-progress';
import FlowbiteStatsInfoIcon from '@/assets/sidebar-icons/FlowbiteStatsInfoIcon.vue';
import { ref, watch, onMounted, computed } from 'vue';
import { useMetrics } from '@/stores/useMetrics';
import { onClickOutside } from '@vueuse/core';
import { formatMilliseconds } from '@/utils/helpers';

import fireIcon from '@/assets/fire-icon.svg';
const metrics_store = useMetrics();

const statsTimeStates = Object.freeze({
  TODAY: 0,
  WEEK: 1,
  MONTH: 2,
});

const stats = ref(false);
const statsTime = ref(statsTimeStates.TODAY);

const streakShown = ref(false);
const progressBarOpacity = ref(false);
const streakOpacity = ref(true);

const target = ref(null);

const completedRatio = computed(() => {
  const { activeUserDailyGoal: goal, activeUserDailyComplete: complete } =
    metrics_store;

  if (!goal) return 0;

  return goal === 0 && complete === 0
    ? 100
    : Math.floor((complete * 100) / goal);
});

onClickOutside(target, event => {
  // This is to allow the info button to act as a toggle
  if (stats.value) setTimeout(() => closeStats(), 50);
});

onMounted(async () => {
  updateStatsDetails();
  setInterval(() => {
    if (
      metrics_store.activeUserDailyGoalStreak != null &&
      metrics_store.activeUserDailyGoalStreak != 0
    )
      if (streakShown.value) {
        streakOpacity.value = true;
        //These timeouts are to properly manage the transitions
        //The second timeouts are neccessary, if the class is changed while the v-show is getting changed
        //the fade in transition won't happen so there needs to be a very slight delay
        setTimeout(() => {
          streakShown.value = !streakShown.value;
          setTimeout(() => (progressBarOpacity.value = false), 100);
        }, 500);
      } else {
        progressBarOpacity.value = true;
        setTimeout(() => {
          streakShown.value = !streakShown.value;
          setTimeout(() => (streakOpacity.value = false), 100);
        }, 500);
      }
  }, 10000);
});

function closeStats() {
  if (stats.value) {
    stats.value = false;
    document.removeEventListener('keydown', handleKeyDown);
  }
}

function openStats() {
  stats.value = true;
  document.addEventListener('keydown', handleKeyDown);
}

const handleKeyDown = event => {
  if (stats.value && event.key === 'Escape') {
    event.preventDefault();
    closeStats();
  }
};

const updateStatsDetails = function () {
  setTimeout(() => metrics_store.getUserMetrics(), 1000);
};

function changeStatsTime(enumValue) {
  if (
    enumValue != statsTimeStates.TODAY &&
    enumValue != statsTimeStates.WEEK &&
    enumValue != statsTimeStates.MONTH
  )
    return;

  statsTime.value = enumValue;
}
const props = defineProps({
  collapsed: {
    default: false,
    type: Boolean,
  },
  collapsedAfterAnimations: {
    default: false,
    type: Boolean,
  },
});

watch(
  () => props.collapsed,
  async newCollapsed => {
    if (newCollapsed) {
      closeStats();
    }
  }
);
</script>

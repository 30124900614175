<template>
  <TitleBar
    v-if="
      !props.notFound && !rbac_store.isFeatureFlagEnabled('SIDEBAR_FLOWBITE')
    "
  />
  <div class="flex flex-row overflow-none h-full">
    <SideBar
      v-if="
        !props.notFound && !rbac_store.isFeatureFlagEnabled('SIDEBAR_FLOWBITE')
      "
      class="mt-[4.5rem]"
    />
    <SidebarFlowbite v-else-if="!props.notFound" />
    <!-- The height here comes from the 4.5em height of the navbar -->
    <div
      :class="
        'overflow-y-auto overflow-x-hidden h-full p-7 w-full ' + pageClasses
      "
    >
      <NotFound v-if="props.notFound" />
      <div v-else :class="'h-full ' + bodyClasses + mutableBodyClasses">
        <slot />
        <div
          v-if="route.name !== 'leadId'"
          class="flex flex-col float-right pt-5 mt-5"
        >
          <SupportPopper
            v-if="!rbac_store.isFeatureFlagEnabled('SIDEBAR_FLOWBITE')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SideBar from '@/components/SideBar.vue';
import SupportPopper from '@/components/SupportPopper.vue';
import SidebarFlowbite from './sidebar/Sidebar-Flowbite.vue';
import TitleBar from '@/components/TitleBar.vue';
import { useRoute } from 'vue-router';
import NotFound from '@/views/common/NotFound.vue';
import { useRbacStore } from '@/stores/useRbac';
import { ref } from 'vue';

const rbac_store = useRbacStore();

const route = useRoute();

const mutableBodyClasses = ref('');
if (!rbac_store.isFeatureFlagEnabled('SIDEBAR_FLOWBITE')) {
  mutableBodyClasses.value = ' pt-[4.5rem]';
}

const props = defineProps({
  pageClasses: {
    default: '',
    type: String,
  },
  bodyClasses: {
    default: '',
    type: String,
  },
  notFound: {
    default: false,
    type: Boolean,
  },
});
</script>
